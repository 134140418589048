import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import { formatPhoneNumber } from "../utils/format_phone"
import Instagram from "../images/Instagram_orange.svg"
import Facebook from "../images/Facebook_orange.svg"
import Twitter from "../images/Twitter_orange.svg"
import Phone from "../images/phone_white.svg"
import Mail from "../images/mail.svg"
import Globe from "../images/globe_white.svg"
import Calendar from "../images/calendar_today_white.svg"
import Qr from "../images/qr_icon.svg"
import FaqImage from "../images/FAQ.svg"
import InfoImage from "../images/info_icon.svg"
import Button from "../components/Button"
import EventList from "../components/trackDetail/EventList"
import Accordion from "../components/Accordion"
import TrackTickets from "../components/tickets/TrackTickets"
import Person from "../images/UserIcon.svg"
import Mobile from "../images/phone_white.svg"
import {
  BreadCrumb,
  TrackLogo,
  ShareButton,
  TrackInfoWrapper,
  TrackDetailWrapper,
  TrackImage,
  TrackName,
  TrackType,
  SocialLink,
  TrackHeaderWrapper,
  TrackBodyWrapper,
  PhoneAndMail,
  WebsiteAndSchedule,
  TrackPhone,
  TrackWeb,
  TrackWebWrapper,
  WebScheduleImg,
  DownloadText,
  SeeMoreEvents,
  DownloadButtons,
  VideoPlayer,
  VideoPlayerMini,
  PurchaseContainer,
  SocialLinkWrapper,
  WebLink,
  DownloadAppText,
  SeeMoreEventsButton,
  FaqQuestion,
  FaqAnswer,
  BreadCrumbContainer,
  LogoContainer,
  TrackAddress,
  HaveQuestion,
  InfoIcon,
  ContactList,
  DetailStyle,
} from "../styles/detail.style"
import { useMediaQuery } from "react-responsive"
import Carousel from "../components/Carousel"
import DownloadApp from "../components/DownloapAppModal"
import { Helmet } from "react-helmet"
import { InfoContainer } from "../styles/accordion.style"
import { ReplaceEmailPhoneWithLink } from "../utils/emailAndPhoneReplacer"

export default function TrackDetails({ data }) {
  const track = data.tickethossGraphql.getTrack
  const tickets = data.tickethossGraphql.getAnnualTicketOptions

  let count = 0
  const [viewMore, setViewMore] = useState(false)

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  const link = track.url ? track.url : ""
  let frameLink = link
  if (
    link.includes("youtube") ||
    link.includes("http://youtu.be") ||
    link.includes("https://youtu.be")
  ) {
    const videoCode = link.split("/")[3]
    frameLink = `https://www.youtube.com/embed/${
      videoCode.includes("watch?v=")
        ? videoCode.replace("watch?v=", "")
        : videoCode
    }`
  }

  const [open, setOpen] = useState()
  const [openOption, setOpenOption] = useState()
  const toggleTicketModal = option => {
    setOpenOption(option)
    setOpen(open => !open)
  }

  const toggleViewMore = () => setViewMore(viewMore => !viewMore)

  const [openDownloadApp, setOpenDownloadApp] = useState(false)
  const openDownloadModal = () => {
    document.body.style.overflow = "hidden"
    setOpenDownloadApp(true)
  }

  const onClose = () => {
    document.body.style.overflow = "visible"
    setOpenDownloadApp(false)
  }

  return (
    <Layout>
      <Helmet>
        <meta property="og:title" content={track.name} />
        <meta property="og:description" content={track.bio} />
        <meta
          property="og:image"
          content={track.facebook_image ? track.facebook_image : track.image}
        />
        <meta property="og:image:width" content="600px" />
        <meta property="og:image:height" content="314px" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={track.name} />
        <meta property="twitter:description" content={track.bio} />
        <meta name="twitter:site" content="@PitPayApp"></meta>
        <meta
          property="twitter:image"
          content={track.twitter_image ? track.twitter_image : track.image}
        />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Event",
            name: track.name,
            image: track.image,
            location: {
              "@type": "Place",
              name: track.name,
              address: {
                "@type": "PostalAddress",
                streetAddress: track.city,
                addressLocality: track.state,
                addressRegion: track.state,
              },
            },
            description: track.bio,
          })}
        </script>
      </Helmet>

      <TrackHeaderWrapper className="Row">
        <BreadCrumbContainer className="Col">
          <BreadCrumb>
            <Link to="/" style={{ color: "#888888" }}>
              Tracks
            </Link>
            <span style={{ color: "#888888" }}> / </span>
            <span style={{ color: "#FA4616" }}>{track.name}</span>
          </BreadCrumb>
        </BreadCrumbContainer>
        <LogoContainer className="Col">
          {track.logo && <TrackLogo src={track.logo} alt={track.name} />}
        </LogoContainer>
        <ShareButton className="Col" style={{ marginTop: 5 }}></ShareButton>
      </TrackHeaderWrapper>

      <TrackBodyWrapper className="Row" padding="0 0 44px 0">
        <TrackInfoWrapper className="Col">
          <TrackImage src={track.image} alt={track.name} />
          <TrackName>{track.name}</TrackName>
          <TrackType>
            {track.size} Mile | {track.type.key}
          </TrackType>

          <SocialLinkWrapper className="Row">
            {track.facebook && (
              <SocialLink
                href={`https://www.facebook.com/${track.facebook}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={Facebook} alt="App Store" />
              </SocialLink>
            )}
            {track.instagram && (
              <SocialLink
                href={`https://www.instagram.com/${track.instagram}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={Instagram} alt="Instagram" />
              </SocialLink>
            )}
            {track.twitter && (
              <SocialLink
                href={`https://twitter.com/${track.twitter}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={Twitter} alt="Twitter" />
              </SocialLink>
            )}
          </SocialLinkWrapper>
          <div style={{ marginTop: 20 }}>
            <TrackAddress>{track.street}</TrackAddress>
            <TrackAddress>
              {track.city}, {track.state} {track.zipcode}
            </TrackAddress>
          </div>
          <PhoneAndMail>
            {track.phone && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={Phone}
                  alt="Phone Icon"
                  width={24}
                  height={24}
                  style={{ verticalAlign: "middle" }}
                />
                <TrackPhone>{formatPhoneNumber(track.phone)}</TrackPhone>
              </div>
            )}
            {track.mail && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={Mail}
                  alt="Mail Icon"
                  width={24}
                  height={16}
                  style={{ verticalAlign: "middle" }}
                />
                <TrackPhone>{track.mail}</TrackPhone>
              </div>
            )}
          </PhoneAndMail>

          <WebsiteAndSchedule className="Row">
            {track.website && (
              <WebLink
                href={
                  track.website.includes("//")
                    ? track.website
                    : `//${track.website}`
                }
                target="_blank"
                rel="noreferrer"
              >
                <TrackWebWrapper className="Col">
                  <WebScheduleImg src={Globe} alt="Globe Icon" />
                  <TrackWeb>Website</TrackWeb>
                </TrackWebWrapper>
              </WebLink>
            )}
            {track.schedule && (
              <WebLink
                href={
                  track.schedule.includes("//")
                    ? track.schedule
                    : `//${track.schedule}`
                }
                target="_blank"
                rel="noreferrer"
              >
                <TrackWebWrapper className="Col">
                  <WebScheduleImg src={Calendar} alt="Globe Icon" />
                  <TrackWeb>Schedule</TrackWeb>
                </TrackWebWrapper>
              </WebLink>
            )}
          </WebsiteAndSchedule>

          <div className="Col" style={{ paddingInline: 0 }}>
            {tickets?.length > 0 && (
              <DownloadAppText>Download App to Purchase</DownloadAppText>
            )}

            <div>
              {isMobile && tickets?.length > 0 && (
                <Carousel>
                  {tickets?.map(ticket => (
                    <div>
                      <div className="btns" key={ticket.type.id}>
                        <Button
                          onClick={() => toggleTicketModal(ticket.type.id)}
                          text={
                            <>
                              <img
                                src={Qr}
                                style={{
                                  marginRight: 13,
                                  verticalAlign: "middle",
                                }}
                                alt="QR"
                                height={30}
                                width={30}
                              />
                              {ticket.type.name}
                            </>
                          }
                          style={{
                            justifyContent: "start",
                            minHeight: ticket.type.name.length < 18 ? 64 : 86,
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </Carousel>
              )}
            </div>

            <div>
              {!isMobile && (
                <PurchaseContainer>
                  {tickets?.map(ticket => (
                    <DownloadButtons
                      key={ticket.type.id}
                      onClick={() => toggleTicketModal(ticket.type.id)}
                      minWidth={
                        ticket.type.name?.length > 20 ? "380px" : "314px"
                      }
                    >
                      <img
                        src={Qr}
                        style={{
                          marginRight: 13,
                          verticalAlign: "middle",
                        }}
                        alt="QR"
                        height={30}
                        width={30}
                      />
                      <p>{ticket.type.name}</p>
                    </DownloadButtons>
                  ))}
                </PurchaseContainer>
              )}
            </div>
          </div>

          {!isMobile && (
            <>
              {(track.promoter_name ||
                track.promoter_phone ||
                track.promoter_email) && (
                <HaveQuestion>
                  Have A Question? Contact the Track Organizer.
                </HaveQuestion>
              )}
              {track.promoter_name && (
                <InfoContainer>
                  <InfoIcon src={Person} alt="Promoter" />
                  <ContactList>{track.promoter_name}</ContactList>
                </InfoContainer>
              )}
              {track.promoter_phone && (
                <InfoContainer>
                  <InfoIcon src={Mobile} alt="Mobile" />
                  <ContactList>{track.promoter_phone}</ContactList>
                </InfoContainer>
              )}
              {track.promoter_email && (
                <InfoContainer>
                  <InfoIcon src={Mail} alt="Mail" />
                  <a
                    href={`mailto: ${track.promoter_email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ContactList>{track.promoter_email}</ContactList>
                  </a>
                </InfoContainer>
              )}
            </>
          )}
        </TrackInfoWrapper>

        <TrackDetailWrapper className="Col">
          <div>
            {track?.eventsByYear.length > 0 &&
              track?.eventsByYear.map(events => {
                return (
                  <>
                    <DownloadText>{events.year} Events</DownloadText>
                    {events.events.map(event => {
                      count += 1
                      if (count < 6)
                        return isMobile ? (
                          <Link to={`/event/${event.id}`} key={event.id}>
                            <EventList
                              name={event.name}
                              date={event.start_date}
                              endDate={event.end_date}
                              status={event.status}
                              id={event.id}
                              openDownloadModal={openDownloadModal}
                              isMultiday={event.isMultiDay}
                            />
                          </Link>
                        ) : (
                          <EventList
                            key={event.id}
                            name={event.name}
                            date={event.start_date}
                            endDate={event.end_date}
                            status={event.status}
                            id={event.id}
                            openDownloadModal={openDownloadModal}
                            isMultiday={event.isMultiDay}
                          />
                        )
                      else
                        return isMobile
                          ? viewMore && (
                              <Link to={`/event/${event.id}`} key={event.id}>
                                <EventList
                                  name={event.name}
                                  date={event.start_date}
                                  status={event.status}
                                  id={event.id}
                                />
                              </Link>
                            )
                          : viewMore && (
                              <EventList
                                key={event.id}
                                name={event.name}
                                date={event.start_date}
                                status={event.status}
                                id={event.id}
                              />
                            )
                    })}
                  </>
                )
              })}
            {
              <SeeMoreEvents onClick={toggleViewMore}>
                {count > 5
                  ? viewMore
                    ? "See Less Events"
                    : "See More Events"
                  : ""}
              </SeeMoreEvents>
            }
            <SeeMoreEventsButton>
              {count > 5 && (
                <Button
                  text={viewMore ? "See Less Events" : "See More Events"}
                  style={{
                    width: "100%",
                    marginTop: 10,
                    minimumHeight: 64,
                    padding: 12,
                    justifyContent: "center",
                    paddingLeft: 17,
                    maxWidth: 327,
                    height: 64,
                    fontSize: "1.5em",
                    lineHeight: "34px",
                    fontWeight: 700,
                    marginBottom: 31,
                    marginInline: "auto",
                  }}
                  onClick={toggleViewMore}
                />
              )}
            </SeeMoreEventsButton>
          </div>

          {frameLink ? (
            <VideoPlayer
              src={frameLink}
              title={track.name}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          ) : null}

          {frameLink ? (
            <VideoPlayerMini
              src={frameLink}
              title={track.name}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          ) : null}

          <DetailStyle>
            {track.bio && (
              <Accordion
                name="About"
                icon={InfoImage}
                text={ReplaceEmailPhoneWithLink(track.bio)}
              />
            )}

            {track.faqs && track.faqs.length > 0 && (
              <Accordion
                name="FAQ"
                icon={FaqImage}
                text={
                  <>
                    {track.faqs.map(faq => {
                      return (
                        <div className="Col" key={faq.id}>
                          <FaqQuestion>{faq.question}</FaqQuestion>
                          <FaqAnswer>{faq.answer}</FaqAnswer>
                        </div>
                      )
                    })}
                  </>
                }
              />
            )}
            {track.infos.map(info => (
              <Accordion
                key={info.id}
                name={info.info.name}
                icon={info.info.icon}
                text={ReplaceEmailPhoneWithLink(info.text)}
                logo={info.logo}
                link={info.link}
              />
            ))}
          </DetailStyle>

          {isMobile && (
            <>
              {(track.promoter_name ||
                track.promoter_phone ||
                track.promoter_email) && (
                <HaveQuestion>
                  Have A Question? Contact the Track Organizer.
                </HaveQuestion>
              )}
              {track.promoter_name && (
                <InfoContainer>
                  <InfoIcon src={Person} alt="Promoter" />
                  <ContactList>{track.promoter_name}</ContactList>
                </InfoContainer>
              )}
              {track.promoter_phone && (
                <InfoContainer>
                  <InfoIcon src={Mobile} alt="Mobile" />
                  <ContactList>{track.promoter_phone}</ContactList>
                </InfoContainer>
              )}
              {track.promoter_email && (
                <InfoContainer>
                  <InfoIcon src={Mail} alt="Mail" />
                  <a
                    href={`mailto: ${track.promoter_email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ContactList>{track.promoter_email}</ContactList>
                  </a>
                </InfoContainer>
              )}
            </>
          )}
        </TrackDetailWrapper>
      </TrackBodyWrapper>

      <TrackTickets
        open={open}
        toggleTicketModal={toggleTicketModal}
        openOption={openOption}
        setOpenOption={setOpenOption}
        tickets={tickets}
        deepLink={`${
          process.env.GATSBY_EXTERNAL_URL
        }?deeplink_path=${encodeURIComponent(`track/${track.id}`)}`}
        openDownloadModal={openDownloadModal}
      />

      {openDownloadApp && <DownloadApp onClose={onClose} />}
    </Layout>
  )
}

export const query = graphql`
  query GetTracks($id: Int!) {
    tickethossGraphql {
      getTrack(input: { id: $id }) {
        id
        name
        image
        logo
        promoter_email
        promoter_phone
        name
        city
        street
        state
        zipcode
        infos {
          info {
            icon
            name
          }
          id
          link
          logo
          text
        }
        distance
        stream_image
        size
        type {
          key
        }
        phone
        facebook
        twitter
        instagram
        website
        schedule
        url
        faqs {
          id
          question
          answer
        }
        bio
        events {
          id
          start_date
          end_date
          name
          status
          isMultiDay
        }
        eventsByYear {
          year
          events {
            id
            start_date
            end_date
            name
            status
            isMultiDay
          }
        }
        facebook_image
        twitter_image
        promoter_name
        promoter_phone
        promoter_email
      }
      getAnnualTicketOptions(input: { track_id: $id }) {
        tickets {
          type
          track_price
          description
          featured_text
          fee
          id
          price
          name
        }
        type {
          id
          name
        }
      }
    }
  }
`
