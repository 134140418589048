import React from "react"
import Button from "../Button"
import Arrow from "../../images/arrow_orange.svg"
import Cancelled from "../../images/cancelled_ribbon.svg"
import Postponed from "../../images/postponed_ribbon.svg"
import {
  MainContainer,
  NameContainer,
  EventDetailButton,
  ButtonsContainer,
  ArrowRight,
  Ribbon,
  EventDetail,
  EventDateAndName,
  EventName,
} from "../../styles/eventList.style"
import DateCard from "../DateCard"

export default function EventList({
  date,
  name,
  status,
  endDate,
  isMultiday,
  track,
  id,
  marginTop = "25px",
  isEvent = false,
  openDownloadModal,
}) {
  return (
    <MainContainer className="Row" status={status} marginTop={marginTop}>
      <EventDateAndName className="Row" isMainEvent={true} status={status}>
        <DateCard
          isMultiday={isMultiday}
          date={date}
          endDate={endDate}
          status={status}
        />
        <NameContainer className="Col">
          <EventName isEvent={isEvent}>{name}</EventName>
          <EventDetail isEvent={isEvent}>{track}</EventDetail>
        </NameContainer>
      </EventDateAndName>
      <ButtonsContainer>
        <Button
          text={"Purchase"}
          style={{
            minWidth: 155,
            minHeight: 56,
            fontSize: 24,
            fontWeight: 700,
            lineHeight: "34px",
          }}
          onClick={openDownloadModal}
        />
        <EventDetailButton to={`/event/${id}`}>Event Details</EventDetailButton>
      </ButtonsContainer>
      {status !== "published" ? (
        <Ribbon
          src={status === "cancelled" ? Cancelled : Postponed}
          alt="Ribbon"
          status={status}
        />
      ) : (
        <ArrowRight src={Arrow} alt="Arrow Right" />
      )}
    </MainContainer>
  )
}
